
.row {
    margin-top: 50px !important;
}

.center {
    display: flex;
    justify-content: center;
}

.button {
    background-color: #343a40 !important;
    color: #17a2b8 !important;
    border: none !important;
}

.table {
    color: #17a2b8 !important;
}