.card {
    color: #17a2b8;
    background-color: black !important;
}

.button, .button:hover {
    background-color: #343a40 !important;
    color: #17a2b8 !important;
    border: none !important;
}

.cardButtons {
    display: flex;
    justify-content: space-around;
    margin-top: 25px;
}

.phoneScreenMargin {
    margin-top: 50px;
}

@media (min-width: 600px){
    .phoneScreenMargin{
        margin-top: 0;
    }
}